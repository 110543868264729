import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "src/app/core/services/common.service";
@Component({
  selector: "app-forgot-password-modal",
  templateUrl: "./forgot-password-modal.component.html",
  styleUrls: ["./forgot-password-modal.component.scss"],
})
export class ForgotPasswordModalComponent implements OnInit {
  isSubmitted: boolean = false;
  myForm: FormGroup;

  constructor(private dialog: MatDialogRef<ForgotPasswordModalComponent>, private fb: FormBuilder, private comm: CommonService) { }

  ngOnInit() {
    this.myForm = this.fb.group({
      email: [null, [Validators.required, this.emailValidator]]
    });
  }
  emailValidator(control) {
    if (control.value) {
      const matches = control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
      return matches ? null : { 'invalidEmail': true };
    } else {
      return null;
    }
  }

  close() {
    let formData = this.myForm.value
    var data = {
      response: "no",
      email: formData.email,
    };
    this.dialog.close(data);
  }

  onSubmit() {
    this.isSubmitted = true;
    let formData = this.myForm.value
    if (this.myForm.valid && this.isSubmitted) {

      var data = {
        response: "yes",
        email: formData.email,
      };
      this.dialog.close(data);
    }
  }
}

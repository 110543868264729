import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PublicApiService {
  constructor() {}
  script = document.createElement("script");

  publicApi(key, type) {
    this.script.type = "text/javascript";
    this.script.src = `https://maps.googleapis.com/maps/api/js?key=${
      key ? key : ""
    }&libraries=places,drawing,geometry,visualization&language=en`;
    this.script.id = "testScriptName";
    this.script.async = true;
    document.head.appendChild(this.script);
  }
}

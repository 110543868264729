import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  APP_INITIALIZER,
} from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import "hammerjs";
import { MaterialModule } from "./modules/material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatepickerModule, BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxSpinnerModule } from "ngx-spinner";
import { DatePipe, AsyncPipe } from "@angular/common";
import { NgxPaginationModule } from "ngx-pagination";
import { RouterModule } from "@angular/router";
import { ToastrModule } from "ng6-toastr-notifications";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmCoreModule } from "@agm/core";
import { AgmDirectionModule } from "agm-direction";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { coreUrlList } from "./core/urlList/core.urlList";
import { CoreModule } from "./core/core.module";
import { AuthGuard } from "./core/guards/auth.guard";
import { GetInterceptorService } from "./core/interceptors/get-interceptor.service";
import { SetInterceptorService } from "./core/interceptors/set-interceptor.service";
import { InitialService } from "./core/services/initial.service";
import { StoreCategoryModalComponent } from "./modules/main/Order-Modules/store/category/storeCategoryModal/storeCategoryModal.component";
import { StoreOutletmodalComponent } from "./modules/main/Order-Modules/store/store/storeOutletModal/storeOutletModal.component";
import { ResetStorePasswordModalComponent } from './modules/main/Order-Modules/store/store/reset-store-password-modal/reset-store-password-modal.component';

import { AddVehicleModalComponent } from "./modules/main/vehicle-type/add-vehicle-modal/add-vehicle-modal.component";
import { AddEditLocationComponent } from "./modules/main/Taxi-Modules/shuttle/location/add-edit-location/add-edit-location.component";
import { SubstoreCategoryModalComponent } from "./modules/main/Order-Modules/store/category/substore-category-modal/substore-category-modal.component";
import { AddEditStoreTypeComponent } from "./modules/main/Order-Modules/store/store-type/add-edit-store-type/add-edit-store-type.component";
import { ImportCategoryModalComponent } from "./modules/main/Order-Modules/store/category/import-category-modal/import-category-modal.component";
import { ImportSubCategoryModalComponent } from "./modules/main/Order-Modules/store/category/import-sub-category-modal/import-sub-category-modal.component";
import { ImportProductsModalComponent } from "./modules/main/Order-Modules/store/manage-products/import-products-modal/import-products-modal.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { PayModalSalesPersonComponent } from "./modules/main/payments/pay-modal-sales-person/pay-modal-sales-person.component";
import { PayModalMerchantsComponent } from "./modules/main/payments/pay-modal-merchants/pay-modal-merchants.component";
import { AddEditTicketComponent } from "./modules/main/Order-Modules/store/tickets/add-edit-ticket/add-edit-ticket.component";
import { AddVehicleModal1Component } from "./modules/main/Taxi-Modules/taxi-booking/vehicle-type-taxi/add-vehicle-modal1/add-vehicle-modal1.component";
import { AddLanguageComponent } from "./modules/main/manage-languages/add-language/add-language.component";
import { AssignordermodalComponent } from "./modules/main/Order-Modules/store/order/assignordermodal/assignordermodal.component";
import { PaymentHistoryModalComponent } from "./modules/main/payments/payment-history-modal/payment-history-modal.component";
import { ImportBrandsComponent } from "./modules/main/manage-brands/import-brands/import-brands.component";
import { AddEditBrandsComponent } from "./modules/main/manage-brands/add-edit-brands/add-edit-brands.component";
import { orderUrls } from "./modules/main/Order-Modules/services/order-urls";
import { taxiUrlList } from "./modules/main/Taxi-Modules/services/taxi-urls";
import { deliveryUrlList } from "./modules/main/Delivery-Modules/delivery/services/delivery-urls";
import {
  ServiceWorkerModule,
  SwPush,
  SwRegistrationOptions,
  SwUpdate,
} from "@angular/service-worker";
import { environment } from "src/environments/environment";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { AddVehicleModelComponent } from "./modules/main/Taxi-Modules/taxi-booking/vehicle-model/add-vehicle-model/add-vehicle-model.component";
import { basicDeliveryUrls } from "./modules/main/Delivery-Modules/basic-delivery/services/basic-delivery-urls";
export function startupServiceFactory(
  InitialService: InitialService
): Function {
  return () => InitialService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    StoreCategoryModalComponent,
    StoreOutletmodalComponent,
    ResetStorePasswordModalComponent,
    AddVehicleModalComponent,
    AddEditLocationComponent,
    SubstoreCategoryModalComponent,
    AddEditStoreTypeComponent,
    ImportCategoryModalComponent,
    ImportSubCategoryModalComponent,
    ImportProductsModalComponent,
    PayModalSalesPersonComponent,
    PayModalMerchantsComponent,
    AddEditTicketComponent,
    AddVehicleModal1Component,
    AddVehicleModelComponent,
    AddLanguageComponent,
    AssignordermodalComponent,
    PaymentHistoryModalComponent,
    ImportBrandsComponent,
    AddEditBrandsComponent,
  ],
  imports: [
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CoreModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AgmDirectionModule,
    GooglePlaceModule,
    NgxIntlTelInputModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    AgmCoreModule.forRoot({}),

    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    NgxSpinnerModule,
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: true,
    //   registrationStrategy: "registerImmediately",
    // }),
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    coreUrlList,
    taxiUrlList,
    deliveryUrlList,
    basicDeliveryUrls,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [InitialService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetInterceptorService,
      multi: true,
    },
    Title,
    AsyncPipe,
    AuthGuard,
    DatePipe,
    orderUrls,
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: true }),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {
  constructor(update: SwUpdate, push: SwPush) {
    update.available.subscribe((update) => {});
  }
}

import { Injectable, Inject, HostBinding } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { Observable, Subject } from "rxjs";
import { Title } from "@angular/platform-browser";
import { PublicApiService } from "./public-api.service";
import { environment } from "src/environments/environment";
import { DataSharingService } from "./data-sharing.service";
@Injectable({
  providedIn: "root",
})
export class InitialService {
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title,
    private _publicApi: PublicApiService,
    private dataSharingService: DataSharingService
  ) { }


  clientBaseUrl = "https://prod.webdevelopmentsolution.net:6029";
  appBaseUrl = "http://orderszds.com/";
  api = environment.baseUrl + environment.commonUrl + "/admin/greatSetting";
  projectSettings: any;
  greatSettings: any;
  appSettings = new Subject();
  async load() {
    let fullUrl = window.location.href;


    // For Static Projects
    this.api = environment.baseUrl + environment.commonUrl + '/admin/greatSetting';
    await this.getGreatSettings();
    this.subscribeToAppSettings();
  }


  subscribeToAppSettings() {
    this.appSettings.subscribe((res) => {
      if (res) {
        this.projectSettings = res;
        console.log(this.projectSettings);


        this.setData();
      }
    });
  }


  async getGreatSettings() {
    let resp = await this.http.get(this.api).toPromise();
    if (resp["response"]["success"] && resp["data"].isActive) {
      this.greatSettings = resp["data"];
      if (this.greatSettings?.appType) {
        this.greatSettings.appType = this.greatSettings.appType.filter(
          (appType) => appType.status == true
        );
        if (this.greatSettings.appType.length == 0) {
          alert("Please select a module from great admin");
        }
        localStorage.setItem(
          "greatSettings",
          JSON.stringify(this.greatSettings)
        );
        localStorage.setItem("appId", this.greatSettings["appId"]);
        this.loadAppSpecificSettings();
      }


      return;
    } else if (!resp["data"].isActive) {
      alert(
        `Project disabled by Apptunix. For any help please contact us on https://appdukaan.com/.`
      );
    } else {
      alert(`Failure From Great Admin !- ${resp["message"]}`);
    }
  }


  async loadAppSpecificSettings() {
    this.http
      .get(environment.baseUrl + environment.commonUrl + "/admin/getAppSetting")
      .subscribe((resp) => {
        if (resp["response"]["success"]) {
          this.projectSettings = resp["data"];
          this.setData();
        }
      });
  }


  setData() {
    // Set On localstorage for global access
    localStorage.setItem("appSettings", JSON.stringify(this.projectSettings));
    // Set Global Color
    if (this.projectSettings && this.projectSettings.colorCodes) {
      document.documentElement.style.setProperty(
        "--primary-color",
        this.projectSettings.colorCodes
      );
    }
    // Set Favicon
    if (this.projectSettings && this.projectSettings.favIcon) {
      this._document
        .getElementById("appFavicon")
        .setAttribute("href", this.projectSettings.favIcon);
    }
    // Set Title
    if (this.projectSettings && this.projectSettings.appName) {
      this.titleService.setTitle(
        this.projectSettings.appName + " - Admin Panel"
      );
      // this.titleService.setTitle(" QuickWorks Admin Panel");
    }
    const appId = localStorage.getItem('appId').toString();
    if (appId) this.dataSharingService.setCurrentAppId(appId);
  }


  public getBaseUrl() {
    if (this.greatSettings) {
      const baseUrl = environment.baseUrl;
      // const clientBaseUrl = this.clientBaseUrl;


      return {
        baseUrl: baseUrl,
        imageUrl: environment.baseUrl,
        // clientBaseUrl: clientBaseUrl,
      };
    }
  }
}

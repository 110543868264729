import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { Observable } from "rxjs";
import { delay, take } from "rxjs/operators";
import { AuthService } from "src/app/modules/auth/services/auth.service";
import { CommonService } from "../services/common.service";
import { HttpService } from "../services/http.service";
import { InitialService } from "../services/initial.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  appBaseUrl = "https://api.webdevelopmentsolution.net";
  constructor(
    private auth: AuthService,
    private initial: InitialService,
    private router: Router,
    private route: ActivatedRoute,
    private _common: CommonService,
    private http: HttpService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (window.location.href.includes("/auth/adminLogin")) {
      console.log(window.location.href);
      let isMulti = false;
      let url: any = window.location.href;
      url = url.split("?")[1].split("=");
      console.log(url);
      if (url[0] === "token") {
        localStorage.setItem("token", url[1].substr(0, url[1].length - 3));
        localStorage.setItem("appId", url[2]);
      }
      this.http.get("/common/v2/admin/greatSetting").subscribe((resp) => {
        if (resp["response"]["success"]) {
          isMulti = resp["data"]["appType"].length > 0 ? true : false;
          localStorage.setItem("greatSettings", JSON.stringify(resp["data"]));
        }
      });

      setTimeout(() => {
        if (localStorage.token && localStorage.appId) {
          this.router.navigate(isMulti ? ["/dashboard"] : ["/dashboards"]);
        }
      }, 1000);
    } else {
      return true;
    }

    if (state.url == "/auth/login") {
      if (
        localStorage.token === null ||
        localStorage.token === undefined ||
        localStorage.token == ""
      ) {
        return true;
      } else {
        this.router.navigate(["/dashboard"]);
        return false;
      }
    } else {
      if (
        localStorage.token == null ||
        localStorage.token == undefined ||
        localStorage.token == ""
      ) {
        console.log("3");
        this.router.navigate(["/auth/login"]);
      } else {
        return true;
      }
    }
  }
}

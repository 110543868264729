import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { OrderService } from "src/app/modules/main/Order-Modules/services/order.service";
@Injectable({
  providedIn: "root",
})
export class SetInterceptorService implements HttpInterceptor {
  appSettings: any;
  constructor(private spinner: NgxSpinnerService,
    private orderservice : OrderService,) {
    // this.appSettings = JSON.parse(localStorage.getItem("appSettings"));
    // console.log('this.appSettings', this.appSettings.appSecretKey);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clonedReq = this.handleRequest(req);
    this.spinner.show();
    return next.handle(clonedReq);
  }

  handleRequest(req: HttpRequest<any>) {
    let fullUrl = window.location.href;
    // apptunx multi module demo
    // let appId = "8mxhvl9uevusjqkp0jjffhdj";
    // let appId = "lfzyulw7hm4qitee9h13pr50";
    let appId = environment.superAdminAppId;

    if (req.url) {
      console.log(" chk request", req);
      
      if (!req.url.includes("driver/v1")) {
        let token = "NA";
        let geofenceId = "NA";
        let timezone = "NA";
        let publicAppId = "NA";

        let zoneId = "NA";

        if (localStorage.getItem("token")) {
          token = localStorage.getItem("token");
        }

        if (localStorage.getItem("appId")) {
          appId = localStorage.getItem("appId");
          publicAppId = appId;
        }
        if (localStorage.getItem("geofenceId")) {
          geofenceId = localStorage.getItem("geofenceId");
        }
        if (localStorage.getItem("geofence")) {
          zoneId = JSON.parse(localStorage.getItem("geofence"))["zoneId"];
        }
        if (localStorage.getItem("Timezone")) {
          timezone = localStorage.getItem("Timezone");
        }
        if (localStorage.getItem("publicAppId")) {
          publicAppId = localStorage.getItem("publicAppId");
        }
        let authReq;
        authReq = req.clone({
          headers: new HttpHeaders({
            Authorization: token,
            geofenceid: geofenceId,
            timeZone: timezone,
            appid: appId,
            zoneId: zoneId,
          }),
        });

        if (req.url.includes("prod.webdevelopmentsolution.net:6046")) {
          authReq = req.clone({
            headers: new HttpHeaders({
              Authorization: token,
              geofenceid: geofenceId,
              timeZone: timezone,
              appid: appId,
              zoneId: zoneId,
              viewType: "apptunix_ondemand",
            }),
          });
        }
        if (req.url.includes("/admin/getCrm")) {
          authReq = req.clone({
            headers: new HttpHeaders({
              Authorization: token,
              geofenceid: geofenceId,
              appid: publicAppId,
            }),
          });
        }
        if (req.url.includes("/admin/getAllFaq")) {
          authReq = req.clone({
            headers: new HttpHeaders({
              Authorization: token,
              geofenceid: geofenceId,
              appid: publicAppId,
            }),
          });
        }

        if (req.url.includes("/webhooks/order/status")) {
          let apiKey;
          if (localStorage.getItem("apiKey")) {
            apiKey = localStorage.getItem("apiKey");
            console.log("chk inc key",apiKey);
          }
          if(this.orderservice.useXapiKey == true){
            authReq = req.clone({
              headers: new HttpHeaders({
                'x-api-key': apiKey,
              }),
            });
          }
        }


        return authReq;
      }
    }
    return req;
  }
}

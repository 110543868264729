export enum TOPUP_MODE {
    SELF = 'SelfTopup',
    COUNTER = 'CounterTopup',
    TRANSACTION = 'Transaction'
}

export enum PAYMENT_TYPE {
    ATM = 'ATM',
    PAYMENT_GATEWAY = 'PaymentGateway',
    CASH = 'Cash',
    BANK_TRANSFER = 'BankTransfer'
}

export enum ENTITY_TYPE {
    USER = 'User',
    DRIVER = 'Driver',
    MERCHANT = 'Merchant',
    FPL = '4PL',
    ADMIN = 'Admin'
}

export enum STATUS_TYPE {
    PENDING = 'Pending',
    ACCEPTED = 'Accepted',
    REJECTED = 'Rejected'
}
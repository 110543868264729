export class taxiUrlList {
  taxi = "/apirides.apptunix.com/v2";

  //renting
  promoCode: string = `${this.taxi}/admin/promocode`;

  // Bookings
  booking: string = `${this.taxi}/shuttle/booking/admin`;
  cancelBookings: string = `${this.taxi}/shuttle/booking/admin/cancel`;
  getShuttleBookingDetail: string = `${this.taxi}/shuttle/booking/admin`;

  // Location
  addShuttleLocation: string = `${this.taxi}/shuttle/location/add`;
  updateShuttleLocation: string = `${this.taxi}/shuttle/location/update`;
  getAllShuttleLocation: string = `${this.taxi}/shuttle/location/getAll`;
  deleteShuttleLocation: string = `${this.taxi}/shuttle/location/delete`;

  getAllShuttleOverview: string = `${this.taxi}/shuttle/booking/seatstats`;
  getAllShuttleLocationAdmin: string = `${this.taxi}/shuttle/location/getAll/admin`;
  getShuttleRouteById: string = `${this.taxi}/shuttle/route/getById`;

  addShuttleRoute: string = `${this.taxi}/shuttle/route/add`;
  updateShuttleRoute: string = `${this.taxi}/shuttle/route/update`;
  getVendersList: string = `${this.taxi}/shuttle/vender/getall`;

  getAllShuttleRoute: string = `${this.taxi}/shuttle/route/getAll`;
  deleteShuttleRoute: string = `${this.taxi}/shuttle/route/delete`;
  addTimeSlotForShuttle: string = `${this.taxi}/admin/addTimeSlot`;
  getShuttleTimeSlots: string = `${this.taxi}/admin/getTimeSlot`;

  getAllShuttleDriver: string = `${this.taxi}/admin/driver/all`;
  getAllFreeRoute: string = `${this.taxi}/shuttle/route/shuttlefree`;

  addShuttle: string = `${this.taxi}/shuttle/add`;
  updateShuttle: string = `${this.taxi}/shuttle/update`;
  getShuttleById: string = `${this.taxi}/shuttle/getById`;

  getAllShuttle: string = `${this.taxi}/shuttle/getAll`;
  deleteShuttle: string = `${this.taxi}/shuttle/delete`;

  updateShuttleStatus: string = `${this.taxi}/shuttle/shuttleStatusUpdate`;

  // Vendor
  getVenderById: string = `${this.taxi}/shuttle/vender/get`;
  addVender: string = `${this.taxi}/shuttle/vender/add`;
  updateVender: string = `${this.taxi}/shuttle/vender/update`;
  getAllVenders: string = `${this.taxi}/shuttle/vender/get`;
  deleteVender: string = `${this.taxi}/shuttle/vender/delete`;
  updateVenderStatus: string = `${this.taxi}/shuttle/vender/vendorStatusUpdate`;

  // Taxi Booking
  getTaxiBooking: string = `${this.taxi}/admin/taxi/bookings`;
  addVehicle: string = `${this.taxi}/admin/taxi/bookings`;
  getTaxiBookingById: string = `${this.taxi}/admin/taxi/booking`;
  vehicalTypes: string = `${this.taxi}/admin/vehicletype`;
  createBooking: string = `${this.taxi}/admin/createTaxiBooking`;
  getDriverList: string = `${this.taxi}/admin/getDriverListByVehicleType`;

  // Taxi Geofancing
  addGeofenceCharge: string = `${this.taxi}/admin/geofenceCharge`;
  getAllGeofenceCharge: string = `${this.taxi}/admin/getAllGeofenceCharge`;
  updateGeofenceCharge: string = `${this.taxi}/admin/geofenceCharge`;

  //renting Module
  getAdminVehicle: string = `${this.taxi}/admin/adminVehicle`;
  updateOrAddAdminVehicle: string = `${this.taxi}/admin/adminVehicle`;
  getVehicleById: string = `${this.taxi}/admin/adminVehicleById`;
}

import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  private walletDataSubject = new BehaviorSubject<any>(null);
  private currentAppIdSubject = new BehaviorSubject<any>(null);

  tempPageForTransaction: number;
  tempPageForPersonal: number;
  tempPageForPayout: number;
  tempPageForTopup: number;
  tempPageForStore: number;
  tempPageForPendingStore: number;
  tempPageForDeals: number;
  tempPageForManagePayment: number;
  tempPageForApprovedMerchandiser: number;
  tempPageForApprovedVerifier: number;
  tempPageForArchivedMerchandiser: number;
  tempPageForArchivedVerifier: number;
  tempPageForPendingMerchandiser: number;
  tempPageForPendingVerifier: number;
  tempPageForSubAdmins: number;
  tempPageForSupport: number;
  isOrderToday = false;
  
  setWalletData(data: any) {
    this.walletDataSubject.next(data);
  }

  setCurrentAppId(appId: string) {
    this.currentAppIdSubject.next(appId);
  }

  getWalletData() {
    return this.walletDataSubject.asObservable();
  }

  getCurrentAppId() {
    return this.currentAppIdSubject.asObservable();
  }

  canUseWalletFeature(): boolean {
    const loggedInUser = JSON.parse(localStorage.getItem('admin'));
    return loggedInUser?.email.toString() === environment.walletEmail;
  }

  getAdminId(): string {
    return JSON.parse(localStorage.getItem('admin'))?._id;
  }
}

<h2 mat-dialog-title>Forgot Password</h2>
<mat-dialog-content>
  <form [formGroup]="myForm">
    <div class="form-group">
      <input
        type="email"
        class="form-control form-control-user"
        name="email"
        id="exampleInputEmail"
        formControlName="email"
        aria-describedby="emailHelp"
        placeholder="Enter Email Address"
        required
      />
    </div>
  </form>
  <div
    *ngIf="
      myForm.get('email').invalid &&
      (myForm.get('email').dirty || myForm.get('email').touched)
    "
    style="color: red"
  >
    Please enter valid email
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="btn btn-primary common-btn mr-2"
    type="submit"
    [disabled]="!myForm.valid"
    (click)="onSubmit()"
  >
    Submit
  </button>
  <button class="btn btn-danger common-btn" (click)="close()">Cancel</button>
</mat-dialog-actions>

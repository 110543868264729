// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  credentials: {
    email: "",
    password: "",
  },
  baseUrl: "https://api.staging.zonedeliveryservices.com",
  merchantUrl: "https://merchant.pharmacy.staging.zonedeliveryservices.com",
  commonUrl: "/common/v2",
  ondemandUrl: "/ondemand/v2",
  socketUrl: "https://apiondemand.staging.zonedeliveryservices.com/",
  errorHandlerArr: [],
  apiKey: "AIzaSyCsPriWr1muoozMnLsy5bFQRgLlZ88bZfU",
  superAdminAppId: 'bgfbdsa93upqr1bl36x7dlq7',
  pharmacyAppId: 'lfzyulw7hm4qitee9h13pr50',
  // walletEmail: 'adminpharmacy@zonedeliveryservices.com'
  walletEmail: 'superadmin@zonedeliveryservices.com'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

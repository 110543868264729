import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  greatSettings: any;
  googleApi: any;
  constructor(
    public myRoute: Router,
    public updates: SwUpdate,
    private router: Router,
    private dialog: MatDialog,
    private http: HttpClient
  ) {
    // navigator.serviceWorker.register("/ngsw-worker.js");
  }

  ngOnInit() {
    // this.updates.available.subscribe((_) =>
    //   this.updates.activateUpdate().then(() => {
    //     document.location.reload();
    //   })
    // );
    // var pointerX = -1;
    // var pointerY = -1;
    // document.onmousemove = function (event) {
    //   pointerX = event.pageX;
    //   pointerY = event.pageY;
    // };
    // setInterval(pointerCheck, 60000);
    // function pointerCheck() {
    //   localStorage.setItem("coordinates", JSON.stringify(pointerX));
    // }
    // setInterval(onSessionCheck, 170000);
    // function onSessionCheck() {
    //   if (pointerX == JSON.parse(localStorage.getItem("coordinates"))) {
    //     if (this.comm) {
    //       this.comm.logout();
    //     }
    //   }
    // }
  }
  // loadScript(text: string) {
  //   return new Promise((resolve, reject) => {
  //     let script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.src = text;
  //     document.getElementsByTagName("head")[0].appendChild(script);
  //     console.log("Script Loaded");
  //     resolve();
  //   });
  // }
}

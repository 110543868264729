import { Injectable } from "@angular/core";
import { HttpService } from "../services/http.service";
import { coreUrlList } from "../urlList/core.urlList";
import { DataSharingService } from "../services/data-sharing.service";
import { ENTITY_TYPE } from "./custom-enums";

@Injectable()
export class WalletMethods {

  constructor(
    private api: HttpService,
    private urlList: coreUrlList,
    private dataSharingService: DataSharingService
  ) { }


  async getSetWallet(storeDetails: object) {
    try {
      const request: object = {
        'entityType': ENTITY_TYPE.ADMIN,
        'entityId': storeDetails['_id'],
        'moduleKey': 'onDemand_superadmin'
      }

      const apiResponse: object = await this.api.post(this.urlList.getSetWallet, request).toPromise()
      if (apiResponse) {
        console.info('Api response after getting/setting wallet is: ', apiResponse);
        const { status, success }: { status: number, success: boolean } = apiResponse['response'];
        if (status === 200 && success === true) {
          this.dataSharingService.setWalletData(apiResponse['data']);
        }
      }
    } catch (error) {
      console.error('Error caught while getting wallet data: ', error);
    }
  }
}
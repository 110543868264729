import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, finalize, tap } from "rxjs/operators";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GetInterceptorService {
  constructor(
    private toaster: ToastrManager,
    private ngxService: NgxSpinnerService,
    private router: Router
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.ngxService.show();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
      finalize(() => {
        this.ngxService.hide();
      }),
      tap(
        (event) => {
          var eventRes = JSON.parse(JSON.stringify(event));

          if (eventRes.body) {
            if (
              eventRes.body &&
              eventRes.body.response &&
              !eventRes.body.response.success
            ) {
              this.toaster.errorToastr(eventRes.body.response.message);
            }
          }
        },
        (error) => {
          console.log(error.status);

          if (error.status === 400) {
            this.toaster.errorToastr(error.error.response.message);
          } else if (environment) {
            environment.errorHandlerArr.push(error.status);
            if (environment.errorHandlerArr.length === 1) {
              if (
                environment.errorHandlerArr[0] === 500 ||
                environment.errorHandlerArr[0] === 404 ||
                environment.errorHandlerArr[0] === 401
              ) {
                if (environment.errorHandlerArr[0] === 401) {
                  this.logOut();
                }
                this.toaster.errorToastr(
                  "Oops!! Something went wrong ! Please Try Again"
                );
              }
            }
            if (environment.errorHandlerArr.length === 5) {
              environment.errorHandlerArr = [];
            }
          }

          // switch (error.status) {
          //   // case 400:
          //   /*** If api responds with bad request  ** */
          //   // this.toaster.errorToastr(error.message);
          //   // this.toaster.errorToastr("Api Not Working");
          //   // break;
          //   case 401:
          //     /***  Auto LogOut if Api response 401 ** */
          //     this.toaster.errorToastr(
          //       "You have been logged out for security purpose."
          //     );
          //     this.logOut();
          //     break;
          //   case 500:
          //     /*** If api does not respond  ** */
          //     this.toaster.errorToastr("Internal Server Error");
          //     // this.toaster.errorToastr("Api Not Working");
          //     break;
          //     // case 404:
          //     /*** If api does not respond  ** */
          //     // this.toaster.errorToastr(error.message);
          //     // this.toaster.errorToastr("Api Not Found!");
          //     break;
          //   default:
          //     if (
          //       error.error &&
          //       error.error.response &&
          //       error.error.response.message
          //     ) {
          //       this.toaster.errorToastr(error.error.response.message);
          //     }
          // }
        }
      )
    );
  }

  logOut() {
    let greatSettings = JSON.parse(localStorage.getItem("greatSettings"));
    let appSettings = JSON.parse(localStorage.getItem("appSettings"));
    localStorage.clear();
    localStorage.setItem("greatSettings", JSON.stringify(greatSettings));
    localStorage.setItem("appId", greatSettings["appId"]);
    localStorage.setItem("appSettings", JSON.stringify(appSettings));
    this.router.navigateByUrl("/auth/login");
  }
}
